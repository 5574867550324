<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama Pemasok"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="address" label="Alamat"></form-input>
              </b-col>
              <b-col sm="12" md="6">
                <form-select ref="citySelect" @value-changed="cityChange" :rules="{required: true}" url="v1/city_select2" v-model="city" label="Kota"></form-select>
              </b-col>
              <b-col sm="12" md="6">
                <form-input :rules="{required: false}" v-model="zip_code" label="Kode Pos"></form-input>
              </b-col>
              <b-col sm="12" md="6">
                <form-input :rules="{required: true}" v-model="tlp" label="Telepon"></form-input>
              </b-col>
              <b-col sm="12" md="6">
                <form-input :rules="{required: false}" v-model="tlp2" label="Telepon 2"></form-input>
              </b-col>
              <b-col sm="12" md="8">
                <form-input :rules="{required: false}" v-model="fax" label="Fax"></form-input>
              </b-col>
              <b-col sm="12" md="8">
                <form-input :rules="{required: false}" v-model="email" label="Email"></form-input>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <b-form-checkbox v-model="is_purchase_tax" switch>Pajak Pembelian</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: false}" v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col>
                <form-checkbox v-model="is_active" label="Aktif"></form-checkbox>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'suppliers'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormCheckbox from '@/my-components/FormCheckbox.vue'
import {BFormGroup,BFormCheckbox} from 'bootstrap-vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import FormSelect from '@/my-components/FormSelect.vue'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    BFormGroup,
    BFormCheckbox,
    FormSelect,
    FormCheckbox
  },
  data(){
    return {
      id: this.$route.params.id||null,
      code:'',
      name:'',
      is_purchase_tax: false,
      address:'',
      city:null,
      name_city:'',
      zip_code:'',
      tlp:'',
      tlp2:'',
      fax:'',
      email:'',
      note:'',
      is_active: true,
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'suppliers/store',
      dispatchShow: 'suppliers/show',
      dispatchUpdate: 'suppliers/update',
    }),
    cityChange(json){
      if(json.label) this.name_city = json.label;
      else this.name_city = '';
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.address = data.address
      this.is_purchase_tax = data.is_purchase_tax ? true : false
      this.is_active = data.is_active ? true : false
      this.city = data.city
      this.name_city = data.name_city
      this.zip_code = data.zip_code
      this.tlp = data.tlp
      this.tlp2 = data.tlp2
      this.fax = data.fax
      this.email = data.email
      this.note = data.note
      this.$refs.citySelect.initOption([{"value":data.city,"label":data.name_city}])
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,name,address,city,name_city,zip_code,tlp,tlp2,fax,email,note} = this
      let params = {id,code,name,address,city,name_city,zip_code,tlp,tlp2,fax,email,note}
      params.is_purchase_tax = this.is_purchase_tax ? 1 : 0
      params.is_active = this.is_active ? 1 : 0
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'suppliers'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>