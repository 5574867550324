<template>
  <b-form-group :label-for="id">
    <b-form-checkbox :name="id" :id="id" @input="$emit('value-changed',bvalue)" v-model="bvalue" switch>{{label}}</b-form-checkbox>
  </b-form-group>
</template>

<script>
import {BFormGroup,BFormCheckbox} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    value:{
      required: true,
      type: Boolean,
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    id(){
      return _.camelCase(this.label)
    },
    bvalue:{
      get() { return this.value },
      set(value) { this.$emit('input', value)}
    }
  },
  components:{
    BFormGroup,
    BFormCheckbox
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
  }
}
</script>

<style>

</style>